@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  height: 100vh;
  overflow: hidden;
}
:root {
  --font-family-sans-serif: "Open Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --mainColor: "#070dbbc7";
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

nav {
  display: block;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #515151;
  text-align: left;
  background-color: #e9edf4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin: 0;
  padding: 0;
}

a {
  /* color: #3f84fc; */
  color: white;

  text-decoration: none;
  background-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Nunito", sans-serif;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2.5rem;
  font-weight: normal;
}

.logo-wraper {
  display: flex;
  align-items: center;
  gap: 6px;
  color: white;
}

input {
  font-size: 16px;
}
::placeholder {
  font-size: 16px;
}
.logo-text {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.2px;
  padding-bottom: 3px;
}
.card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}

#grafana-dashboard {
  height: calc(100vh - 90px);
}
.card-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  text-align: center;
}

.dashboard {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
}

.dashboard-app header {
  height: 56px;
}
.dashboard-app {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 2;
  -webkit-flex-grow: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  margin-top: 80px;
}

.dashboard-content {
  -webkit-box-flex: 2;
  -webkit-flex-grow: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.dashboard-nav {
  max-width: 240px;
  min-width: 240px;
  /* padding: 15px 15px 55px; */
  width: 240px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  background-color: #373193;
  overflow-x: hidden;
}

.dashboard-compact .dashboard-nav {
  display: none;
}

.dashboard-nav header {
  min-height: 56px;
  padding: 8px 27px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dashboard-nav header .menu-toggle {
  display: none;
  margin-right: auto;
}

.dashboard-nav a:hover {
  text-decoration: none;
}

.dashboard-nav {
  background-color: #443ea2;
  z-index: 1070;
}

.dashboard-nav a {
  color: #fff;
}

.brand-logo {
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  font-size: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #515151;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brand-logo:focus,
.brand-logo:active,
.brand-logo:hover {
  color: #dbdbdb;
  text-decoration: none;
}

.brand-logo i {
  color: #d2d1d1;
  font-size: 27px;
  margin-right: 10px;
}

.dashboard-nav-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dashboard-nav-item {
  padding: 8px 0px 8px 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.02em;
  transition: ease-out 0.2s;
  cursor: pointer;
}

/* .dashboard-nav-item i {
  width: 36px;
  font-size: 19px;
  margin-left: -50px;
} */

/* .dashboard-nav-item:hover {
  background: #070dbbc7;

} */

/* .active {
  background: #070dbbc7;
} */

.dashboard-nav-dropdown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dashboard-nav-dropdown.show {
  background: rgba(255, 255, 255, 0.04);
}

.dashboard-nav-dropdown.show > .dashboard-nav-dropdown-toggle {
  font-weight: bold;
}

.dashboard-nav-dropdown.show > .dashboard-nav-dropdown-toggle:after {
  -webkit-transform: none;
  -o-transform: none;
  transform: none;
}

.dashboard-nav-dropdown.show > .dashboard-nav-dropdown-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.menuItem {
  display: flex;
  flex-wrap: noWrap;
  width: 100%;
}

#chevron-icon {
  position: absolute;
  right: 12px;
}
.dashboard-nav-dropdown-toggle:after {
  content: "";
  margin-left: auto;
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(81, 81, 81, 0.8);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.dashboard-nav .dashboard-nav-dropdown-toggle:after {
  border-top-color: rgba(255, 255, 255, 0.72);
}

.dashboard-nav-dropdown-menu {
  display: none;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.dashboard-nav-dropdown-item {
  /* min-height: 40px; */
  padding: 8px 20px 8px 60px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  transition: ease-out 0.2s;
  white-space: noWrap;
}
.dashboard-nav-sub-menu {
  /* min-height: 40px; */
  padding: 8px 20px 8px 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  transition: ease-out 0.2s;
  white-space: noWrap;
}

.dashboard-nav-dropdown-item:hover {
  background: rgba(255, 255, 255, 0.04);
}

.menu-toggle {
  position: relative;
  width: 42px;
  height: 42px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: white;
  /* color: #443ea2; */
}

.menu-toggle:hover,
.menu-toggle:active,
.menu-toggle:focus {
  text-decoration: none;
  color: #875de5;
}

.menu-toggle i {
  font-size: 20px;
}

.my-header {
  justify-content: space-between !important;
}

.dashboard-toolbar {
  min-height: 56px;
  background-color: #dfdfdf;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 27px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}

.nav-item-divider {
  height: 1px;
  margin: 1rem 0;
  overflow: hidden;
  background-color: rgba(236, 238, 239, 0.3);
}

@media (min-width: 992px) {
  .dashboard-app {
    margin-left: 238px;
  }

  .dashboard-compact .dashboard-app {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .dashboard-content {
    padding: 15px 0px;
  }
}

@media (max-width: 992px) {
  .dashboard-nav {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1070;
  }

  .dashboard-nav.mobile-show {
    display: block;
  }
}

@media (max-width: 992px) {
  .dashboard-nav header .menu-toggle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 992px) {
  .dashboard-toolbar {
    left: 200px;
  }

  .dashboard-compact .dashboard-toolbar {
    left: 0;
  }
}

.header-bar {
  display: flex;
  align-items: center;
  gap: 4;
}
.header-bar input {
  height: 30px;
  width: 400px;
  background: transparent;
  outline: none;
  border-radius: 5px;
  padding: 0 12px;
  font-size: 16px;
  border: none;
  border: 1px solid #d7d6d5;
  transition: all 0.2s ease;
}
.header-bar input::placeholder {
  color: #ffffff;
}
.header-bar input:focus {
  color: #ffffff;
  border: 1px solid rgb(250, 247, 247);
}
i {
  font-size: 16px !important;
  cursor: pointer !important;
  /* color: #9299bb; */
}

#sidebar .nav-item {
  font-size: 12px !important;
  color: #333;
}
/* body::-webkit-scrollbar-track{
  display: none;
}
body::-webkit-scrollbar{
  display: none;
} */
#sidebar::-webkit-scrollbar-track {
  width: 4px; /* Set the width of the scrollbar track */
  background-color: rgb(
    200,
    192,
    192
  ); /* Set the background color of the scrollbar track */
}

#sidebar::-webkit-scrollbar {
  width: 4px; /* Set the width of the scrollbar */
  background-color: blue; /* Set the background color of the scrollbar */
}

#sidebar::-webkit-scrollbar-thumb {
  background-color: lightblue; /* Set the color of the scrollbar thumb */
  border-radius: 10px; /* Set the border radius of the scrollbar thumb */
}
.text-gradient {
  background-image: linear-gradient(to left, #7002ff, #000000, #151dbe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.toggle-menu-btn {
  display: none;
  transform: display 0.4s ease-in;
}

#grafana-dashboar:hover .toggle-menu-btn {
  display: flex;
  transform: display 0.4s ease-in;
}

#septa .header-expended-button {
  font-size: 20px;
  margin-right: 9px;
  padding-bottom: 0px !important;
  color: #ffffff;
}
#septa .septa-close-button {
  font-size: 24px;
  color: #ffffff;
  padding-right: 10px;
  padding-left: 5px;
  padding-bottom: 2px !important;
}

#septa .septa-input {
  overflow: hidden;
  display: flex;
  flex: 1;
}
#septa .septa-input::placeholder {
  font-size: 14px;
}

#septa .septa-send-button {
  margin-left: 10px;
  padding-top: 0px !important;
}
#septa .septa-icon {
  align-items: center;
}
